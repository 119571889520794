<template>
    <main>
        <div class="ui center aligned container">
            <img class="ui centered medium logo image" src="~@/assets/images/logo/1991spwg.png"/>
            <div class="ui hidden divider"></div>
            <div class="ui compact center aligned red very padded segment">
                <div v-if="token">
                    <h1 class="ui poppins weight-400 header" style="font-size: 2.25rem;">Did Not Register?</h1>
                    <p class="ui grey text">We are sorry to learn that your email was misused on our platform.<br/>Please confirm this by clicking on the button below.</p>
                    <div class="ui hidden divider"></div>
                    <p><button class="ui black button" :class="{loading: reporting}" type="button" @click="report" :disabled="reporting">CONFIRM</button></p>
                </div>
                <div v-else>
                    <h1 class="ui poppins weight-400 header" style="font-size: 2.25rem;">401 Unauthorized</h1>
                    <p class="ui grey text">You are not authorized on this page.</p>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
export default {
    data() {
        return {
            reporting: false,
            token: this.$route.query.u || ""
        };
    },
    methods: {
        report() {
            this.reporting = true;
            this.$http.post("users/report/registration", {
                token: this.token
            }).finally(() => {
                this.reporting = false;
                this.$toasted.show("Email reported. Thank you.", {
                    position: "bottom-center",
                    duration: 3000
                });
            });
        }
    }
};
</script>

<style lang="scss" scoped>
main {
    height: 100vh;
    background: #f7f8fa;

    .ui.compact.segment {
        margin: auto;
        max-width: calc(100vw - 2rem);
    }
}
</style>